import { useNavigate } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import {
  GoogleAuthProvider,
  type User,
  linkWithPopup,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { atom, useAtom } from "jotai";
import { useCallback, useEffect, useMemo } from "react";
import { auth } from "~/libs/firebase.client";

const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/gmail.send");

/**
 * User object when logged in
 * null when not logged in
 * undefined when loading
 */
type CurrentAuthUserAtom = User | null | undefined;

export const currentAuthUserAtom = atom<CurrentAuthUserAtom>(undefined);

export function AuthProvider({
  children,
  isExpiredToken,
}: { children: React.ReactNode; isExpiredToken?: boolean }) {
  const [currentAuthUser, setCurrentAuthUser] = useAtom(currentAuthUserAtom);

  // 認証の状態変更を監視
  useEffect(() => {
    const unsubscribed = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser?.email) Sentry.setUser({ email: firebaseUser?.email });
      setCurrentAuthUser(firebaseUser);
    });
    return () => unsubscribed();
  }, [setCurrentAuthUser]);

  // トークン期限切れの場合に自動で再認証を試みる
  useEffect(() => {
    // トークンが期限切れで、かつユーザーがログイン済みの場合に実行
    if (isExpiredToken && currentAuthUser) {
      const refreshToken = async () => {
        try {
          // トークンを強制的に更新
          const newToken = await currentAuthUser.getIdToken(true);

          // リロードして新しいトークンでセッションを更新
          if (newToken) {
            window.location.reload();
          }
        } catch (error) {
          console.error("Failed to refresh token:", error);
          // トークン更新に失敗した場合はログアウトして、ログインページにリダイレクト
          window.location.href = "/logout";
        }
      };

      refreshToken();
    }
  }, [isExpiredToken, currentAuthUser]);

  return children;
}

export function useAuth() {
  const navigate = useNavigate();

  const [currentAuthUser] = useAtom(currentAuthUserAtom);

  const getters = useMemo(
    () => ({
      isLogin: !!currentAuthUser,
      isFetching: currentAuthUser === undefined,
      isLinkedWithGoogle: currentAuthUser?.providerData.some(
        (provider) => provider.providerId === "google.com"
      ),
    }),
    [currentAuthUser]
  );

  const signInWithSlackToken = useCallback((token: string) => {
    return signInWithCustomToken(auth, token)
      .then((userCredential) => {
        return { status: "success", userCredential };
      })
      .catch((_error) => ({
        status: "failure",
        userCredential: undefined,
      }));
  }, []);

  /**
   * NOTE: https://firebase.google.com/docs/auth/web/account-linking?hl=ja
   */
  const signInWithGoogle = useCallback(async () => {
    try {
      const result = currentAuthUser
        ? await linkWithPopup(currentAuthUser, provider)
        : await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const _token = credential?.accessToken;
      return { status: "success" };
    } catch (_error) {
      return {
        status: "failure",
      };
    }
  }, [currentAuthUser]);

  const _signOut = useCallback(async () => {
    await signOut(auth);
    navigate("/logout");
  }, [navigate]);

  return {
    ...getters,
    signInWithSlackToken,
    signInWithGoogle,
    signOut: _signOut,
  };
}

export async function checkFirebaseTokenExp(firebaseUser: User) {
  const idTokenResult = await firebaseUser.getIdTokenResult();
  const exp = +(idTokenResult.claims.exp || 0);
  const diffMinutes = (exp - Date.now() / 1000) / 60;

  return diffMinutes < 10;
}
